import React, { useState, useEffect } from "react";
import location from "../../assets/img/icon/location.svg";
import email from "../../assets/img/icon/email.svg";
import telephone from "../../assets/img/icon/telephone.svg";
import "../../assets/css/style.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import env1 from "../../assets/img/contact-us/env1.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import "./Input.css";
import OtpInput from "./OtpInput";
import { Button } from "react-bootstrap";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = () => {
  //number verification
  const [isLoading, setIsLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [open, setOpen] = useState(false);
  const [otpValidated, setOtpValidated] = useState(null);
  const [incorrectOTP, setIncorrectOTP] = useState(false);
  const [ipAddress, setIpAddress] = useState(null);
  const [OtpId, setOtpId] = useState(null);
  const [otp2, setOtp] = useState(() => new Array(6).fill(""));
  const [countdown, setCountdown] = useState(150);
  const [countdownStarted, setCountdownStarted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const user = "contact";
  const domain = "edeekshaam.in";
  const contactEmail = `${user}@${domain}`;
  const key = '6LdHKnYpAAAAAPTKnd7qy3WbgskAUi0e-8-1N1P8';

  //email verification
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const [popupType, setPopupType] = useState("");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const formatValue = (input) => {
      let cleanedValue = input.replace(/[^a-zA-Z0-9\s]/g, "");
      if (!/^[a-zA-Z]/.test(cleanedValue)) {
        cleanedValue = cleanedValue.slice(1);
      }
      return cleanedValue;
    };
    if (name === "firstName" || name === "lastName") {
      const formattedValue = formatValue(value);
      setFormData({ ...formData, [name]: formattedValue });
      setErrors({ ...errors, [name]: "" });
    } else if (name === "mobileNumber") {
      const formattedValue = value.replace(/\D/g, "").slice(0, 10);
      setFormData({ ...formData, [name]: formattedValue });
      setErrors({ ...errors, [name]: "" });
    } else {
      setFormData({ ...formData, [name]: value });
      setErrors({ ...errors, [name]: "" });
    }
  };

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org/?format=json");
        setIpAddress(response.data.ip);
      } catch (error) {
        toast.error("Error fetching IP address:", error);
      }
    };
    fetchIpAddress();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (type) => {
    setPopupType(type);
    setOpen(true);
  };

  const handleSendOtp = () => {
    if (formData.mobileNumber.length === 10) {
      setLoading(true);
      const payload = { mobileNumber: formData.mobileNumber };
      axios
        .post(`${process.env.REACT_APP_API_URL}/users/public/sendOTP`, payload)
        .then((response) => {
          setOtpId(response.data.response.otp_id);
          toast.success("OTP sent successfully", { position: "top-right" });
          setOtpSent(true);
          setCountdown(150);
          handleClickOpen("mobileNumber");
          setOtp(new Array(6).fill(""));
          setIncorrectOTP(false);
        })
        .catch((error) => toast.error(error.response.data.error))
        .finally(() => setLoading(false));
    } else {
      toast.error("Please enter your mobile number");
    }
  };

  const handleSendEmailOtp = () => {
    if (isValidEmail(formData.email)) {
      setLoading1(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}/users/public/send-otp`, {
          email: formData.email,
        })
        .then(() => {
          toast.success("OTP sent to your email", { position: "top-right" });
          handleClickOpen("email");
          setOtp(new Array(6).fill(""));
          setIncorrectOTP(false);
        })
        .catch(() => toast.error("Error sending OTP"))
        .finally(() => setLoading1(false));
    } else {
      toast.error("Please enter your email address");
    }
  };

  const handleVerifyEmailOtp = () => {
    const combinedOtp = otp2.join("");
    if (!combinedOtp) {
      toast.error("OTP is required", { position: "top-right" });
      return;
    }
    const payload = {
      email: formData.email,
      otp: combinedOtp,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/users/public/verify-otp`, payload)
      .then(() => {
        toast.success("Email OTP validated successfully", {
          position: "top-right",
        });
        setIsEmailVerified(true);
        handleClose();
        setIncorrectOTP(false);
        setErrors({});
      })
      .catch(() => toast.error("Invalid OTP. Please enter the correct OTP."));
  };

  const handleResendOTP = () => {
    if (formData.mobileNumber.length === 10) {
      const payload = { mobileNumber: formData.mobileNumber };
      axios
        .post(`${process.env.REACT_APP_API_URL}/users/public/sendOTP`, payload)
        .then((response) => {
          setOtpId(response.data.response.otp_id);
          toast.success(response.data.response.message, {
            position: "top-right",
          });
          setOtpSent(true);
          setCountdown(150);
          handleClickOpen("mobileNumber");
          setIncorrectOTP(false);
          setOtp(new Array(6).fill(""));
        })
        .catch((error) => toast.error(error.response.data.error));
    } else {
      toast.error("Invalid Mobile Number");
    }
  };

  const handleVerifyNumber = () => {
    handleSendOtp();
    setCountdownStarted(true);
  };

  const handleVerifyEmail = () => {
    handleSendEmailOtp();
  };

  useEffect(() => {
    if (countdownStarted) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      if (countdown <= 0) {
        clearInterval(timer);
      }
      return () => clearInterval(timer);
    }
  }, [countdownStarted, countdown]);

  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;

  const onOtpSubmit = () => {
    if (popupType === "mobileNumber") {
      const combinedOtp = otp2.join("");
      if (!combinedOtp) {
        toast.error("OTP is required", { position: "top-right" });
        return;
      }
      const payload = {
        otp_Id: OtpId,
        mobileNumber: formData.mobileNumber,
        otp: combinedOtp,
        ipAddress: ipAddress.toString(),
      };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/users/public/receivedOTP`,
          payload
        )
        .then(() => {
          toast.success("OTP validated successfully", {
            position: "top-right",
          });
          setOtpValidated(true);
          setIsMobileVerified(true);
          setIncorrectOTP(false);
          setTimeout(() => {
            handleClose();
          }, 1000);
          setErrors({});
        })
        .catch(() => {
          setIncorrectOTP(true);
          setOtpValidated(false);
          toast.error("Invalid OTP. Please enter the correct OTP.");
        });
    } else if (popupType === "email") {
      handleVerifyEmailOtp();
    }
  };

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.firstName.trim())
      newErrors.firstName = "Please enter your first name";
    if (!formData.lastName.trim())
      newErrors.lastName = "Please enter your last name";
    if (!formData.email.trim() && !formData.mobileNumber.trim()) {
      newErrors.contact = "Please enter either an email or a mobile number";
      toast.error(newErrors.contact);
    }

    if (formData.email) {
      if (!isValidEmail(formData.email)) {
        newErrors.email = "Please enter a valid email address";
      } else if (!isEmailVerified) {
        newErrors.email = "Please verify your email with OTP";
      }
    }

    if (formData.mobileNumber) {
      if (formData.mobileNumber.length !== 10) {
        newErrors.mobileNumber = "Please enter a valid mobile number";
      } else if (!isMobileVerified || !otpValidated) {
        newErrors.mobileNumber = "Please verify your mobile number with OTP";
      }
    }
    if (!formData.message) newErrors.message = "Please write your concern";

    if (!captchaVerified) {
      toast.error("Please complete the CAPTCHA verification");
      return;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setIsSubmit(true);
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/public/getInTouch`,
        formData
      );

      if (response.status === 200) {
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          mobileNumber: "",
          message: "",
        });
        setIsMobileVerified(false);
        setIsEmailVerified(false);
        Swal.fire({
          title: "Success!",
          text: "Your message has been submitted. Thank you!",
          icon: "success",
          customClass: { confirmButton: "email-success-popup" },
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

  return (
    <>
      <Helmet>
        <title>Contact Us - Get in Touch with edeekshaam</title>
        <meta
          name="description"
          content="Reach out to edeekshaam for any inquiries, support, or collaboration opportunities. We’re here to assist parents, educators, and special education institutions."
        />
        <link rel="canonical" href="https://www.edeekshaam.in/contact-us" />
      </Helmet>
      <div className="main-wrapper">
        <div className="page-banner">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h1 className="mb-0">Contact us</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-7 mb-3 mb-lg-0">
              <div
                className="support-wrap p-1"
                style={{ width: "100%", height: "100%" }}
              >
                <iframe
                  style={{
                    width: "inherit",
                    height: "inherit",
                    padding: "10px",
                  }}
                  src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15034.167098348324!2d78.36107973729702!3d17.430546496156715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1680255417198!5m2!1sen!2sin"
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="support-wrap">
                <img
                  className=""
                  style={{ width: "20px" }}
                  src={location}
                  alt="location"
                />
                <h4 className="mt-3">Address</h4>
                <p className="lh-lg">
                  Department of Computer Science & Information Technology,
                  <br />
                  Maulana Azad National Urdu University,
                  <br />
                  Gachibowli, Hyderabad, Telangana, India - 500032
                </p>
              </div>
              <div className="support-wrap mt-3">
                <img
                  className=""
                  style={{ width: "20px" }}
                  src={email}
                  alt="email"
                />
                <h4 className="mt-2">Mail us</h4>
                <Link to={`mailto:${contactEmail}`}>
                  <p>{contactEmail}</p>
                </Link>
                <img
                  className=""
                  style={{ width: "20px" }}
                  src={telephone}
                  alt="telephone"
                />
                <h4 className="mt-2">Call us on</h4>
                <p className="m-0">
                  <a href="tel:04023006612">040 23006612 (Extn: 4002)</a>
                </p>
                <p className="m-0">
                  Call Timing: 9:30 AM to 5:30 PM (Monday to Friday)
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-lg-3">
          <div className="row">
            <div className="col-lg-6 d-flex justify-content-center align-items-center">
              <img
                className="img-fluid"
                src={env1}
                alt="envalope"
                style={{ maxWidth: "40%" }}
              />
            </div>
            <div className="col-lg-6 col-md-8 mx-auto mt-5 pt-5 mb-3 mb-lg-0">
              <form onSubmit={handleSubmit}>
                <p className="text-primary">
                  Note: All the fields marked with (*) are mandatory. You must
                  provide either an email or mobile number.
                </p>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name *"
                        name="firstName"
                        maxLength={50}
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                      {errors.firstName && (
                        <p className="text-danger">{errors.firstName}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name *"
                        name="lastName"
                        maxLength={50}
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                      {errors.lastName && (
                        <p className="text-danger">{errors.lastName}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="form-group"
                  style={{ padding: "3px", position: "relative" }}
                >
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email *"
                    name="email"
                    value={formData.email}
                    disabled={isEmailVerified}
                    maxLength={100}
                    onChange={handleChange}
                  />
                  <button
                    type="button"
                    className="text-small"
                    onClick={!isEmailVerified ? handleVerifyEmail : null}
                    style={{
                      position: "absolute",
                      right: "30px",
                      transform: "translateY(-125%)",
                      cursor: isEmailVerified ? "not-allowed" : "pointer",
                      border: "none",
                      background: "transparent",
                      color: "#1565C0",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "small",
                    }}
                    disabled={loading || isEmailVerified}
                  >
                    {loading1 ? (
                      <CircularProgress size={16} />
                    ) : isEmailVerified ? (
                      <>Verified</>
                    ) : (
                      <>Verify email</>
                    )}
                  </button>
                  {errors.email && (
                    <p className="text-danger">{errors.email}</p>
                  )}
                </div>

                {/* OR Seperator Start */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    style={{
                      flexGrow: 1,
                      height: "1px",
                      backgroundColor: "gray",
                    }}
                  ></div>
                  <span
                    style={{
                      padding: "0 15px",
                      color: "gray",
                      fontWeight: "bold",
                    }}
                  >
                    OR
                  </span>
                  <div
                    style={{
                      flexGrow: 1,
                      height: "1px",
                      backgroundColor: "gray",
                    }}
                  ></div>
                </div>
                {/* OR Seperator End */}

                <div
                  className="form-group"
                  style={{ padding: "3px", position: "relative" }}
                >
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Mobile Number *"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    disabled={isMobileVerified}
                    maxLength={10}
                    onChange={handleChange}
                  />
                  <button
                    type="button"
                    className="text-small"
                    onClick={!isMobileVerified ? handleVerifyNumber : null}
                    style={{
                      position: "absolute",
                      right: "30px",
                      transform: "translateY(-125%)",
                      cursor: isMobileVerified ? "not-allowed" : "pointer",
                      border: "none",
                      background: "transparent",
                      color: "#1565C0",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "small",
                    }}
                    disabled={loading || isMobileVerified}
                  >
                    {loading ? (
                      <CircularProgress size={16} />
                    ) : isMobileVerified ? (
                      <>Verified</>
                    ) : (
                      <>Verify number</>
                    )}
                  </button>
                  {errors.mobileNumber && (
                    <p className="text-danger">{errors.mobileNumber}</p>
                  )}
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder="Write your message (up to 400 characters) *"
                    rows={4}
                    maxLength={400}
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                  {errors.message && (
                    <p className="text-danger">{errors.message}</p>
                  )}
                </div>
                <div className="form-group text-center">
                  <ReCAPTCHA
                    sitekey={key}
                    onChange={handleCaptchaChange}
                  />
                </div>
                {isLoading ? (
                  <button
                    className="btn-submit p-1 mb-3"
                    disabled={isSubmit || isLoading || !captchaVerified}
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Sending...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn-submit px-3 py-1"
                    disabled={!captchaVerified}
                  >
                    Submit
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title" className="text-primary">
          Enter OTP sent to your{" "}
          {popupType === "email" ? (
            <>{formData.email}</>
          ) : (
            <> {formData.mobileNumber}</>
          )}
        </DialogTitle>
        <DialogContent>
          <OtpInput
            length={6}
            otp={otp2}
            setOtp={setOtp}
            onOtpSubmit={onOtpSubmit}
          />
          {countdown === 0 && otpSent && !otpValidated && (
            <Grid md={6} xs={12} sm={12} style={{ padding: "5px" }}>
              <div>
                <span
                  onClick={handleResendOTP}
                  style={{
                    cursor: "pointer",
                    font: "small-caption",
                    color: "var(--primary)",
                  }}
                >
                  <span style={{ color: "gray" }}>Didn’t receive OTP? </span>
                  Resend OTP
                </span>
              </div>
            </Grid>
          )}
          {countdown > 0 && otpSent && !otpValidated && (
            <Grid md={6} xs={12} sm={12} style={{ padding: "5px" }}>
              <div>
                <span style={{ font: "small-caption", color: "gray" }}>
                  Resend OTP in {minutes.toString().padStart(2, "0")}:
                  {seconds.toString().padStart(2, "0")}
                </span>
              </div>
            </Grid>
          )}
          {incorrectOTP && (
            <p style={{ color: "red" }}>
              Invalid OTP. Please enter the correct OTP.
            </p>
          )}
          <p
            style={{
              color: "#ff0000",
              font: "small-caption",
              padding: "5px",
            }}
            className="text-primary"
          >
            Note: OTP will expire after 2:30 minutes.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={onOtpSubmit} className="mt-2">
            Submit
          </Button>
          <Button onClick={handleClose} className="mt-2">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </>
  );
};

export default ContactUs;
